import React, { useState, useEffect } from "react";
import axios from "axios";

const DisplayArrayOrMessage = ({ data }) => {
  if (Array.isArray(data)) {
    return (
      <ul>
        {data.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  } else {
    return <h2>{data}</h2>;
  }
};

const MyComponent = () => {
  const [phone, setPhone] = useState("");
  const [zip, setZip] = useState("");
  const [responseMessage, setResponseMessage] = useState(null);
  const [isAllowed, setIsAllowed] = useState(true);

  const currentDate = new Date();

  // Subtract one day from the current date
  const previousDate = new Date();
  previousDate.setDate(currentDate.getDate() - 3);

  // Format the previous date as a string (e.g., "2023-10-14")
  const formattedPreviousDate = previousDate.toISOString().split("T")[0];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const headers = {
      Authorization: "Bearer WgLI1Xs98x8UncTlN9Jo2ZtrTlWKrNnUaXeOzxJo",
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        "https://leadamp.clearlink.com/api/v1/calls/ping",
        {
          campaignCode: "MEDCPA1",
          affiliateLeadId: "123456789",
          zip,
          phone,
          custom: {
            medicarePartAB: "Y",
          },
          scope_of_appointment: {
            timestamp: formattedPreviousDate,
            products: ["all"],
          },
        },
        { headers }
      );

      setResponseMessage(response.data.accepted);
      setPhone("");
      setZip("");
    } catch (error) {
      if (error.response.data.errors) {
        setResponseMessage(error.response.data.errors);
      } else {
        setResponseMessage(error);
      }
      setPhone("");
      setZip("");
    }
  };

  useEffect(() => {
    // Function to fetch the user's IP address (This is just a basic example)
    // const fetchUserIP = async () => {
    //   try {
    //     const response = await fetch("https://api.ipify.org?format=json");
    //     const data = await response.json();
    //     const userIP = data.ip;
    //     // List of allowed IPs
    //     const allowedIPs = ["124.109.39.193"];
    //     setIsAllowed(allowedIPs.includes(userIP));
    //   } catch (error) {
    //     console.error("Error fetching user IP:", error);
    //   }
    // };

    // fetchUserIP();

    // Function to handle right-click
    const handleRightClick = (event) => {
      event.preventDefault();
    };

    // Function to handle F12 keypress
    const handleKeyPress = (event) => {
      if (event.key === "F12") {
        event.preventDefault();
      }
    };

    // Add event listeners to detect right-click and F12 keypress
    document.addEventListener("contextmenu", handleRightClick);
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div>
      {isAllowed ? (
        <div>
          <form onSubmit={handleSubmit}>
            <br />
            <h4>Phone Number</h4>
            <input
              placeholder="Enter Phone Number"
              style={{ width: "250px", height: "40px" }}
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <br />
            <h4>Zip Code</h4>
            <input
              placeholder="Enter Zip Code"
              style={{ width: "250px", height: "40px" }}
              type="text"
              name="zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
            <br />
            <br />
            <button
              style={{
                width: "130px",
                height: "40px",
                backgroundColor: "#007BFF",
                color: "#fff",
              }}
              type="submit"
            >
              Submit
            </button>
          </form>
          <DisplayArrayOrMessage data={responseMessage} />
        </div>
      ) : (
        <h1>Access denied!</h1>
        // You can also redirect the user to an error page here
      )}
    </div>
  );
};

export default MyComponent;
